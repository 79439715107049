import React from "react"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Layout from "../components/layout"
import styled from "styled-components"
import Container from "../components/Container"
import { Link } from "gatsby"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const NotFoundPage = () => (
  <Layout>
    <Header />
    <Container>
      <FourInner>
        <h1>404 NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
        <TeamAllLink>
          <Link to="/">Back to Homepage</Link>
        </TeamAllLink>
      </FourInner>
    </Container>
    <Footer />
  </Layout>
)
export default NotFoundPage

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const FourInner = styled.div`
  margin-top: 20vh;
  text-align: center;
  h1 {
    margin-bottom: 15px;
    font-size: 70px;
  }
  p {
    font-size: 25px;
    margin-bottom: 55px;
  }
  height: 40vh;
`

const TeamAllLink = styled.div`
  text-align: center;
  a {
    background-color: ${(props) => props.theme.green};
    padding: 12px 40px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    &:hover {
      background: ${(props) => props.theme.dark};
      color: ${(props) => props.theme.white};
      transform: scale(1.05);
    }
  }
`
